.navbar {
    background-image: linear-gradient(140deg, #0086d4 0%, #015794 50%, #00e2db 75%);
}

.button-gradient {
    background-image: linear-gradient(140deg, #0086d4 0%, #015794 50%, #00e2db 75%);
}

.button-gradient:hover {
    background-image: linear-gradient(140deg, #0086d4 0%, #015794 10%, #00e2db 75%);
}
/* Webkit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #3498db; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners of the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
}

/* Firefox */
/* For Firefox, you can use scrollbar-width and scrollbar-color */
/* Note: These properties are not supported in Webkit browsers */

body {
  scrollbar-width: thin; /* "thin" or "auto" */
  scrollbar-color: #3498db #f1f1f1; /* Color of the thumb and track */
}

/* Optional: Adjust the width and color as per your preference */


body {
    overflow-x: hidden;
}